import React, { useRef, useContext } from 'react';
import styles from './Hero.module.scss';
import Button from '~/components/shared/Button';
import AddressForm from '~/components/shared/AddressFormBrand';
import { PhoneRebrand, SummerSavings } from '~/components/icons';
import { InfoCircle } from '~/components/icons/InfoCircle';
import PhoneNumber from '~/components/shared/Fuse/PhoneNumber';
import Typography from '~/components/shared/Typography';
import { CallCenterContext } from '~/providers/CallCenterProvider';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import LegalAnnotation from '~/components/shared/LegalAnnotation';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useMedia } from '~/helpers/hooks';
import { PROMO_END_DATE } from '~/constants/legal-info';
import { useMonarchPreampPlacementContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  addressCTACopy: 'Shop new plans',
  addressLabelColor: '#333',
  addressLabelCopy: 'Enter your address to check available plans',
  addressPlaceHolderCopy: 'Enter your address',
  backgroundImage: 'coastline-hero',
  callCTACopy: 'Call',
  callCTAIconColor: '#154B8E',
  callCTATextColor: '#154B8E',
  globalBanner: true,
  globalBannerHeaderCopy: 'SUPER SUMMER SAVINGS',
  globalBannerSubHeaderCopy:
    'Powered by JUPITER™ 3, the largest commercial communications satellite.',
  headerCopy: 'Connect, stream and play at up to 100 Mbps.',
  headerCopy2: 'N/A',
  headerCopy2Toggle: false,
  headerFontColor: '#0E4275',
  headerLegal: 'generic-speed',
  headerLegalToggle: true,
  hideOfferHeadline: false,
  highPropHero: false,
  mapPinIconColor: '#154B8E',
  offerBoxBackgroundColor: 'white',
  offerBoxHeader1Copy:
    'Order now and receive a $100 Prepaid Mastercard® via rebate.',
  offerBoxHeader1FontColor: '#0e4275',
  offerBoxHeader1Legal: 'mail-in-rebate-legal',
  showOfferEndDate: true,
  offerEndDateLegal: 'change',
  offerEndDateLegalToggle: false,
  showGlobalBannerSubHeader: false,
  showHeaderTooltip: false,
  subheadLegal: 'generic-speed',
  subheadLegalToggle: false,
  subheaderCopy1:
    'Our Hughesnet Fusion<sup>®</sup> plan combines satellite and wireless technologies to reduce latency for a more responsive internet experience.',
  subheaderCopy2:
    'The Hughesnet Fusion<sup>®</sup> plan combines satellite and wireless technologies in a way no other provider in America can offer.',
  subheaderCopy2Toggle: false,
  subheaderFontColor: '#333',
  tooltipCopy: "I'm a Editable Tippy Tooltip!",
  mobileHeroDiff: false,
  mobileHeroImage: 'american-heritage-mobile',
  globalBannerHeader2Copy: 'Save $25/mo. for 12 months',
  globalBannerHeader2LegalCopy: '12-mos-legal',
  globalBannerHeader2LegalToggle: true,
  showBannerHeader2: true,
  showBannerIcon: true,
};

export default function MirHero() {
  const offerHero = useRef();
  const isMedia = useMedia();
  const { data: monarchData } = useMonarchPreampPlacementContext();
  const data = { ...BASE_DATA, ...monarchData };
  const {
    headerCopy,
    headerCopy2,
    headerCopy2Toggle,
    headerLegal,
    headerLegalToggle,
    headerFontColor,
    subheaderCopy1,
    subheaderCopy2,
    subheaderCopy2Toggle,
    subheaderFontColor,
    offerBoxBackgroundColor,
    offerBoxHeader1Copy,
    offerBoxHeader1Legal,
    offerBoxHeader1FontColor,
    callCTACopy,
    callCTATextColor,
    callCTAIconColor,
    subheadLegal,
    subheadLegalToggle,
    highPropHero,
    backgroundImage,
    hideOfferHeadline,
    showHeaderTooltip,
    tooltipCopy,
    globalBanner,
    globalBannerHeaderCopy,
    showBannerHeader2,
    globalBannerHeader2Copy,
    globalBannerSubHeaderCopy,
    globalBannerSubHeaderLegalToggle,
    globalBannerSubHeaderLegal,
    showBannerIcon,
    offerEndDateLegal,
    offerEndDateLegalToggle,
    showOfferEndDate,
    showGlobalBannerSubHeader,
    mobileHeroDiff,
    mobileHeroImage,
    globalBannerHeader2LegalCopy,
    globalBannerHeader2LegalToggle,
  } = data;

  useIntersectionObserver(
    [offerHero],
    () =>
      fireElementViewedEvent(
        'Offer Hero',
        'Offer Hero section',
        'Offer Hero',
        headerCopy
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );

  const callCenterOpen = useContext(CallCenterContext);
  let bgImage = backgroundImage
    ? `/images/heroes/${backgroundImage}.jpg`
    : `/images/heroes/offer-hero-hn-redesign-v1.jpg`;
  if (mobileHeroDiff && isMedia.mobile) {
    bgImage = `/images/heroes/${mobileHeroImage}.jpg`;
  }
  const removeTopMargin = backgroundImage == 'farmhousehero';
  const showBannerSubcopy = showGlobalBannerSubHeader && isMedia.desktop;

  return (
    <div
      id='hero'
      ref={offerHero}
      className={styles.container}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      {globalBanner && (
        <div className={styles.globalBanner}>
          <span className={styles.head}>{globalBannerHeaderCopy}</span>
          {showBannerIcon && (
            <>
              &nbsp; <SummerSavings className={styles.bannerIcon} />
              &nbsp;
            </>
          )}
          {!isMedia.mobile && (
            <>
              {showBannerHeader2 && (
                <span className={styles.head}>{globalBannerHeader2Copy}</span>
              )}
              {globalBannerHeader2LegalToggle && (
                <span className={styles.head}>
                  <LegalAnnotation annotation={globalBannerHeader2LegalCopy} />
                </span>
              )}
              {showBannerSubcopy && (
                <>
                  &nbsp; &nbsp;
                  <span className={styles.subHead}>
                    {globalBannerSubHeaderCopy}
                    {globalBannerSubHeaderLegalToggle && (
                      <span>
                        <LegalAnnotation
                          annotation={globalBannerSubHeaderLegal}
                        />
                      </span>
                    )}
                  </span>
                </>
              )}
            </>
          )}
        </div>
      )}
      <div className={('grid-container', styles.content)}>
        <div
          className={`${styles.headers}
        ${highPropHero && styles.highPropHeaders}`}
        >
          <Typography
            variant='h1'
            className={`${styles.heading} ${
              removeTopMargin && styles.removeTopMargin
            }`}
          >
            <span
              dangerouslySetInnerHTML={{ __html: headerCopy }}
              style={{ color: headerFontColor }}
            />
            {headerLegalToggle && (
              <span
                className={styles.heroSup}
                style={{ color: headerFontColor }}
              >
                <LegalAnnotation annotation={headerLegal} />
              </span>
            )}{' '}
            {showHeaderTooltip && (
              <Tippy
                content={
                  <p key='paragraph-1' className='tooltip-content--legal'>
                    {tooltipCopy}
                  </p>
                }
                placement='right'
                arrow={true}
                trigger='mouseenter'
                theme='dark'
                className='tooltip-icon'
              >
                <button key='button-1' className='tooltip-btn' role='button'>
                  <InfoCircle />
                  <span key='span-2' className='screen-reader-text'></span>
                </button>
              </Tippy>
            )}
            {headerCopy2Toggle && (
              <span
                dangerouslySetInnerHTML={{ __html: headerCopy2 }}
                style={{ color: headerFontColor }}
              />
            )}
          </Typography>
          <Typography variant='p' className={styles.subheading}>
            <span
              dangerouslySetInnerHTML={{ __html: subheaderCopy1 }}
              style={{ color: subheaderFontColor }}
            />
            {subheadLegalToggle && (
              <span
                className={styles.bodySup}
                style={{ color: subheaderFontColor }}
              >
                <LegalAnnotation annotation={subheadLegal} />
              </span>
            )}{' '}
            {subheaderCopy2Toggle && (
              <span
                dangerouslySetInnerHTML={{ __html: subheaderCopy2 }}
                style={{ color: subheaderFontColor }}
              />
            )}
          </Typography>
        </div>

        <div
          className={`${styles.offerBox} ${
            hideOfferHeadline && styles.offerBoxMin
          }`}
          style={{ backgroundColor: offerBoxBackgroundColor }}
        >
          <div>
            {!hideOfferHeadline && !isMedia.mobile && (
              <div className={styles.offerContainer}>
                <div className={styles.offerValueBox}>
                  <div className={styles.offerValue}>$100</div>
                  <div className={styles.offerSmallText}>Prepaid card</div>
                </div>
                <Typography variant='h3' className={styles.offerHeading}>
                  <span
                    dangerouslySetInnerHTML={{ __html: offerBoxHeader1Copy }}
                    style={{ color: offerBoxHeader1FontColor }}
                  ></span>
                  <span className={styles.bodySup}>
                    <LegalAnnotation annotation={offerBoxHeader1Legal} />
                  </span>
                  <br />
                  <span className={styles.offerEnd}>
                    {showOfferEndDate && `Offer ends ${PROMO_END_DATE}`}
                    {offerEndDateLegalToggle && (
                      <LegalAnnotation annotation={offerEndDateLegal} />
                    )}
                  </span>
                </Typography>
              </div>
            )}
            <div className={styles.addressContainer}>
              <AddressForm metadata={data} isHero={true} />
            </div>
          </div>
          {callCenterOpen && (
            <Button
              tracking={{
                position: 'HERO',
                location: 'Offer Hero - iHN Re-Design',
                elementType: 'BUTTON',
                actionOutcome: 'Click to call',
                text: 'Call now',
                htmlId: '',
              }}
              fuse
              className={styles.callNowCta}
              style={{
                color: callCTATextColor,
              }}
              isSecondary
            >
              <PhoneRebrand color={callCTAIconColor} />
              <span className={styles.callNowCtaText}>{callCTACopy} </span>{' '}
              <PhoneNumber />
            </Button>
          )}
          {!hideOfferHeadline && isMedia.mobile && (
            <div className={styles.offerContainer}>
              <div className={styles.offerValueBox}>
                <div className={styles.offerValue}>$100</div>
              </div>
              <Typography variant='h3' className={styles.offerHeading}>
                <span
                  dangerouslySetInnerHTML={{ __html: offerBoxHeader1Copy }}
                  style={{ color: offerBoxHeader1FontColor }}
                ></span>
                <span className={styles.bodySup}>
                  <LegalAnnotation annotation={offerBoxHeader1Legal} />
                </span>
                <br />
                <span className={styles.offerEnd}>
                  {showOfferEndDate && `Offer ends ${PROMO_END_DATE}`}
                  <LegalAnnotation annotation={offerEndDateLegal} />
                </span>
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
